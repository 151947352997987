@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-invitation-beneficiaire{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    font-family: 'Montserrat', sans-serif;
}

.text-pharmacie-invit{
    margin:0
}

.form-invitation-beneficiaire{
    margin-top: 30px;
}

.form-invitation-beneficiaire:last-child{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.text-input-beneficiaire-invit{
    margin-top: 20px;
}

.text-pharmacie-name-invit {
    font-size: 20px;
    font-weight: bold;
}