@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.img-size {
    width: 561px;
    height: 352.5px;
    margin-top: 60px;
    margin-bottom: 60px;
}

.container-sizeMode {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    padding-left: 50px;
    padding-right: 50px;
}

.text-sizeMode {
    text-align: center;
}

@media (max-width: 590px) {
    .img-size {
        width: 323px;
        height: 203px;
    }
}

@media (max-width: 320px) {
    .img-size {
        width: 300px;
        height: 175px;
    }
}