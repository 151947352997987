@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Fond sombre semi-transparent */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    /* Taille de la pop-up */
    width: 35%;
    max-width: 350px;
    display: flex;
    align-items: center;
    flex-direction: column;
    
  }

  .modal-content0 {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    /* Taille de la pop-up */
    width: 80%;
    max-width: 1340px;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
  }

  .text-modal{
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    text-align: center;
  }

  .button-modal{
    height: 40px;
    width: 120px;
    background-color: #1B1C3C;
    color: white;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
  }

  .container-button-modal{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }

  .button-modal-second{
    height: 40px;
    width: 120px;
    background-color: #BEBEBE;
    color: white;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
    border: none;
  }

  .text-modal-ask{
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    margin: 0;
    font-size: 20px;
    text-align: center;
    color: #1B1C3C;
  }

  .button-modal-deco{
    height: 40px;
    width: 160px;
    background-color: #1B1C3C;
    color: white;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    font-size: 18px;
    cursor: pointer;
  }