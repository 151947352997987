@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-formation{
    display: flex;
}

.container-all-data-formation{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background-image: url('../../../assets/img/fond-formation.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 320px;
}

.container-content-formation{
    justify-content: center;
    height: 50%;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
}