@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-challenges{
    display: flex;
}

.container-all-data-challenges{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-left: 320px;
}

.container-without-header{
    display: flex;
    flex-direction: column;
    padding: 20px 55px;
    overflow-y: auto;
}

.container-bloc-stats{
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    height: 300px;
    margin-bottom: 30px;
    position: relative;
}

.container-bloc-challenge-op{
    display: flex;
    height: 100px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 30px;
}

.container-chart-value{
    display: flex;
}

.container-chart{
    height: 100%;
    width: 25vw;
    background-color: #fff;
}

.container-value{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.text-chart{
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    padding: 15px;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.container-history{
    display: flex;
    align-items: center;
    position: absolute;
    font-family: 'Montserrat', sans-serif;
    right: 0;
    bottom: 0;
    padding: 0 40px 20px 0;
}

.icon-history{
    height: 24px;
    margin-right: 5px;
}

.text-history{
    cursor: pointer;
}

.pointer{
    cursor: pointer;
}
