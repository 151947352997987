@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-profilEmail{
    display: flex;
}

.container-all-data-profilEmail{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-left: 320px;
}

.container-form-profilEmail{
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}