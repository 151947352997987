@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

body{
    margin: 0;
    padding: 0;
}

.container-homePage{
    display: flex;
}

.fade-in-out {
    animation: fadeEffect 1s ease-in-out forwards;
  }
  
  @keyframes fadeEffect {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  .image-bandeau {
    position: relative;
    width: 100%;
    background: #fff;
    height: 27%;
    display: flex;
    justify-content: center;
    border-left: 1px solid #D9D9D9;
    overflow-x: hidden;
  }  
  
  .slider-dots {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  
  .slider-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .slider-dot.active {
    background-color: #000;
  }
  

.img-bandeau-container{
    height: 100%;
}

.img-bandeau-container-title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.img-bandeau-container-title h1, .img-bandeau-container-title h2 {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
}

.img-bandeau-container-title h1 {
    font-size: 2vw;
    font-weight: 900;
}

.img-bandeau-container-title h2 {
    font-weight: 600;
}

.container-all-data{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-left: 320px;
}

.container-all-info{
    height: 65%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-challenge{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    min-width: 413px;
    height: 100%;
    max-width: 800px;
}

.container-box{
    position: relative;
    z-index: 1;
    width: 90%;
    height: 160px;
    max-height: 200px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    background-color: #fafafa;
    margin-bottom: 10%;
}

.image-challenge{
    background-color: #D9D9D9;
    width: 40%;
    height: 90%;
    max-width: 800px;
}

.subtitle-challenge{
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    margin: 40px;
}

.container-element-box{
    justify-content: flex;
    flex-direction: column;
}

.subtitle-box{
    display: flex;
    flex-direction: column;
    padding: 20px 0 0px 20px
}

.subtitle-intro{
    font-size: 16px;
    color: #474748;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

.more-weight{
    font-size: 20px;
}

.text-gain{
    font-size: 16px;
    color: #474748;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    padding: 20px 0 0 20px
}

.text-value-gain{
    font-size: 32px;
    font-style: normal;
    font-weight: 750;
    margin: 0;
    line-height: normal ; 
    font-family: 'Montserrat', sans-serif;
    color: #7CB4A8;
    padding: 0 0 0 20px
}

.container-moreInfo-value{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-progressBar-text{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.text-unit{
    margin: 0;
    color: #474748;
    font-family: 'Montserrat', sans-serif;
}

.container-moreInfo{
    position: absolute;
    bottom: 0;
    right: 0;
}

.container-all-data-without-header {
    overflow-y: auto;
    overflow-x: hidden;
}

.img-pub-chall {
    width: 506px;
}

@media screen and (max-width: 1330px){
    .img-pub-chall {
        width: 90%;
    }
}