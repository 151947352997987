@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.circle-background{
    fill: none;
    stroke: #D9D9D9;
}

.circle-progress{
    fill: none;
    stroke: #7CB4A8;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.text-circle{
    font-family: 'Montserrat', sans-serif;
    color: #474748;
    font-size: 20px;
    font-weight: 600;
}