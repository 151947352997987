@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-step-password {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-family: 'Montserrat', sans-serif;
}

.container-step-password:nth-child(4){
    width: 284px;
}

.title-step-password {
    margin: 0;
    font-size: 48px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    text-transform: uppercase;
}

.text-step-password {
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.container-input-step-password{
    display: flex;
    flex-direction: column;
}

.container-input-step-password:last-child{
    margin-top: 30px;
}

.container-content-step-password{
    display: flex;
    flex-direction: row;
    align-items: end;
}

.input-step-password{
    border-radius: 10px;
    background-color: #E8E6E5;
    width: 284px;
    height: 39px;  
    border: none;
    padding-left: 20px;
}

.submit-btn-password{
    bottom: 0;
    border-radius: 10px;
    color: white;
    background-color: #1B1C3C;
    width: 190px;
    height: 50px;
    margin-left: 30px;
}

.input-code-step-password{
    width: 60px;
    height: 70px; 
    border-radius: 10px;
    border: none;
    background-color: #E8E6E5;
    margin-left: 10px;  
    text-align: center;
    font-size: 24px;
}


.input-code-step-password:first-child{
    margin: 0
}

.container-content-text-password{
    display: flex;
}

.underline{
    text-decoration: underline;
}

.wrong-code{
    font-weight: 700;
    color: red
}

.right-code{
    font-weight: 700;
    color: green
}

.top{
    margin-top: 30px
}

.content-red-message{
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.text-red-message{
    margin: 0;
    color: red;
    font-size: 20px;
}

.error-message-password{
    color: red;
    font-size: 18px;
    font-weight: bold;
    margin-left: 20px;
}