@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-inconuPage{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: relative;
}

.container-content-inconuPage{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.img-inconnuPage {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.text-inconuPage {
    font-family: 'Montserrat', sans-serif;
    color: white
}

.btn-inconuPage {
    display: flex;
    justify-content: center;
    border-radius: 10px;
    background: #1B1C3C;
    height: 50px;
    width: 250px;
    cursor: pointer;
}

.title-inconuPage {
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 60px;
    margin-top: 50px;
}