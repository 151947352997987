@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-errorPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.title-errorPage{
    font-family: 'Montserrat', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 350;
    line-height: normal;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
    margin-bottom: 25px;
    margin-top: 50px;
}

.text-errorPage{
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    margin-bottom: 50px;
}

.btn-errorPage{
    display: flex;
    justify-content: center;
    border-radius: 10px;
    background: #1B1C3C;
    height: 50px;
    width: 300px;
}

.text-btn-errorPage{
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.img-other-errorPage{
    height: 50%;
    max-height: 300px;
}