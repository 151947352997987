.container-bloc-statistiques{
    display: flex;
    justify-content: end;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
}

.container-chart-statistiques{
    width: 70%;
}

.container-value-statistiques{
    display: flex;
    flex-direction: column;
    justify-content: end;
    width: 300px;
}

.text-chart-bold{
    font-size: 20px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
}

.subtitle-challenges-historique{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 24px;
    margin: 0;
    margin-bottom: 15px;
    padding-top: 4vh;
}