@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-without-header-details-challenge{
    display: flex;
    flex-direction: column;
    padding: 20px 50px;
    overflow-y: auto;
}

.container-timer-details-challenge-trimestriel-0{
    padding-top: 0.2vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 24.667px 74.002px 0px rgba(144, 144, 144, 0.32) inset;
}

.container-timer-details-challenge-trimestriel-1{
    padding-top: 0.2vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


.timer-details-challenge-trimestriel-big-container-0 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}

.timer-details-challenge-trimestriel-big-container-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8vw;
}

.timer-details-challenge-trimestriel-container-0 {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.timer-details-challenge-trimestriel-container-1 {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.timer-details-challenge-trimestriel-value-0 {
    font-size: max(4.5vw, 85px);
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #474748;
    margin: 0;
}

.timer-details-challenge-trimestriel-value-1 {
    font-size: 1vw;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #474748;
    border-radius: 10px;
    box-shadow: 0px 0px 7px 3px rgba(144, 144, 144, 0.20) inset;
    width: 3vw;
    min-width: 45px;
    height: 65px;
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.timer-details-challenge-trimestriel-text-0 {
    font-size: max(1.6vw, 20px);
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #474748;
    margin: 0;
}

.timer-details-challenge-trimestriel-text-1 {
    font-size: max(0.6vw, 12px);
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    margin: 0;
    color: #474748;
}

.timer-details-challenge-trimestriel-separator-0 {
    font-size: 2.5vw;
    padding-bottom: 3vh;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #474748;
}

.timer-details-challenge-trimestriel-separator-1 {
    font-size: 1vw;
    padding-bottom: 1vh;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #474748;
}

.bandeau-ventes-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5vh;
    margin-bottom: 15px;
}

.bandeau-ventes-title {
    color: #1B1C3C;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 35px;
    margin: 0;
    margin-bottom: 15px;
}

.button-ventes-add {
    background-color: #1B1C3C;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 57px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    border-radius: 15px;
    padding: 2px 25px;
    color: #fff;
}

.timer-finished-details-challenge-trimestriel-text-0 {
    font-size: 1.5vw;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #474748;
}

.timer-finished-details-challenge-trimestriel-text-1 {
    font-size: 0.8vw;
    padding-right: 0.2vw;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #474748;
}

.container-text-help-ventes {
    font-family: 'Montserrat', sans-serif;
}

.text-help-ventes-bold {
    font-weight: bold;
}
.container-add-file {
    display: flex;
    justify-content: end;
    gap: 10px;
}

.container-vente-info p span{
    font-weight: 900;
}

.container-vente-info {
    display: flex;
    font-family: 'Montserrat', sans-serif;
    align-items: center;
    color:#B72222 ;
    gap: 1%;
}