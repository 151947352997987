@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-status{
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    min-width: 115px;
}

.svg-status{
    margin-right: 5px;
}