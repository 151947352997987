.container-validPassword{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 10px;
    margin-top: 10px;
    min-width: 300px;
}

.validPassword-step{
    border-radius: 5px;
    background-color: rgb(151, 151, 151);
    width: 23%;
}

.step-1{
    background-color: red
}

.step-2{
    background-color: #FFA500
}

.step-3{
    background-color: #FFA500
}

.step-4{
    background-color: green
}