@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-beneficiaires{
    display: flex;
}

.container-all-data-beneficiaires{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-left: 320px;
}

.container-without-header-beneficiaires{
    display: flex;
    padding: 20px 0 20px 55px;
    overflow-y: auto;
    height: 100%;
    justify-content: space-between;
}

.subtitle-beneficiaires{
    color: #474748;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 28px;
    margin: 0;
    margin-bottom: 16px;
}
.img-pub-beneficiaires {
    width: 100%;
    height: 100%;
}

.container-pub-beneficiaires{
    height: 100%;
    max-width: 450px;
    background-color: #D9D9D9;
    margin-right: 70px;
    margin-left: 15px;
}

@media screen and (max-width: 1599px){
    .container-pub-beneficiaires{
        margin-right: 20px;
    }
}

@media screen and (max-width: 1499px){
    .container-pub-beneficiaires{
        display: none;
    }
}

