.container-maintenance{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
}

.title-maintenance{
    margin: 0;
    font-size: 40px;
    font-family: sans-serif;
}

.subtitle-maintenance{
    margin: 0;
    font-family: sans-serif;
}