@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-moreInfo{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.text-moreInfo{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: '#1B1C3C';
    font-weight: 700;
    margin: 0;
    margin-left: 5px;
    margin-bottom: 9px;
    margin-right: 21px;
    width: 100px;
}

.icon-moreInfo{
    height: 24px;
    margin-bottom: 6px;
}