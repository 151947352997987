@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

a{
    text-decoration: none;
    color: black
}

.text-subtitle-collaps {
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}

.text-title-collaps{
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    font-weight: 700;
    margin: 0;
    color: #1B1C3C;
}

.header{
    display: flex;
    margin-bottom: 20px;
}

.container-tab-collaps{
    border-radius: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 70px;
    display: flex;
    align-items: center;
}

.container-tab-collaps:last-child{
    margin-bottom: 30px;
    position: relative;
}

.table-collaps{
    width: 100%;
}

.header-document-box{
    display: flex;
}

.text-document-box{
    font-family: 'Montserrat', sans-serif;
    margin: 0
}

.link-document-box{
    text-decoration: none;
}

.container-link-folder{
    display: flex;
    height: 100%;
    align-items: center;
}

.link-document-box-container {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .link-document-box {
    margin-left: 8px;
    margin-top: 3px;
    font-family: 'Montserrat', sans-serif;
  }

  /* .one{
    padding-left: 5%;
  }

  .two{
    padding-left: 15%;
  } */

  .three{
    position: absolute;
    right: 0;
    margin-right: 5%;
  }
  
  .collapsible-component{
    margin-bottom: 30px;
  }

  .text-document-box-title:last-child{
    margin-left: 19%;
    font-family: 'Montserrat', sans-serif;
  }

  .text-document-box-title{
    font-family: 'Montserrat', sans-serif;
    margin-left: 8%;
    font-weight: bold;
  }

  .pointer{
    cursor: pointer;
  }
  @media screen and (min-width: 1548px){

    .second {
      margin-left: -7%;
  }
}
@media screen and (min-width: 1549px) and (max-width:1648){

  .second {
    margin-left: -2%;
}
}
@media screen and (min-width: 1649) and (max-width:1945px){

  .second {
    margin-left: -4%;
}
}
  @media screen and (min-width: 1549px){

 
    .text-document-box-title:last-child{
        margin-left: 18%;
        font-family: 'Montserrat', sans-serif;
      }
    
      .text-document-box-title{
        font-family: 'Montserrat', sans-serif;
        margin-left: 8%;
      }

     
}

@media screen and (min-width: 1649px){
    .text-document-box-title:last-child{
        margin-left: 17%;
        font-family: 'Montserrat', sans-serif;
      }
    
      .text-document-box-title{
        font-family: 'Montserrat', sans-serif;
        margin-left: 8%;
      }
}


.container-bloc-challenge-trimestre {
  display: flex;
  align-items: center;
  height: 100px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  margin-bottom: 15px;
}

.container-challenge-value {
  display: flex;
  flex-direction: column;
  padding-right: 8%;
}

.container-tab-collaps-text {
  width: 35%;
}
.second {
  position: absolute;
  left: 35%;
}

.first{
  margin-left: 2%;
}