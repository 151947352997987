@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.search-bar-container {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 40px;
  }
  
  .input-searchBar {
    padding-left: 30px;
    border-radius: 10px;
    height: 40px;
    width: 290px;
    border: none;
    background-color: #E8E6E5;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    padding-left: 40px;
  }
  
  .icon-searchBar {
    position: absolute;
    left: 10px;
    pointer-events: none;
  }