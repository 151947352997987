@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.title-listingElement {
    width: 290px;
    height: 40px;
    background-color: #1B1C3C;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.title-listingElement:first-child {
    border-top-left-radius: 10px;
}

.title-listingElement:last-child {
    border-top-right-radius: 10px;
}

.item-listingElement {
    width: 19vw;
    min-width: 230px;
    height: 40px;
    background-color: #E8E6E5;
    color: #8a8a8a;
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
}

.container-params-beneficiaire {
    display: flex;
    justify-content: space-between;
    min-width: 460px;
    margin-top: 10px;
}

.container-icon-value-beneficiaire {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}

.icon-update{
    height: 24px;
    margin-right: 10px;
}

.icon-add-beneficiaire{
    height: 24px;
    margin-right: 10px;
}

.delete-text{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin-left: 5px;
    cursor: pointer;
}

.container-delete-box{
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 5px;
    width: 1px;
    cursor: pointer;
}

.item-listingElement.highlighted {
    color: #474748;
}

.hidden{
    display: none;
}

.cursor{
    cursor: pointer;
}

.container-button-modal {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }

  .form-group {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .text-label-input {
    width: 120px;
    margin-right: 10px;
    font-family: 'Montserrat', sans-serif;
    margin-right: 10px;
  }
  
  .input {
    flex: 1
  }

  .pointer{
    cursor: pointer;
  }
