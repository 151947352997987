.container-documents{
    display: flex;
}

.container-all-data-documents{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-left: 320px;
}

.container-without-header-document{
    display: flex;
    flex-direction: column;
    padding: 20px 55px 20px 55px;
    overflow-y: auto;
    height: 100%;
}