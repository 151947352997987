.container-product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    gap: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    padding: 25px 15px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    width: 200px;
    min-width: 200px;
    margin: 15px;
}

.intern-container-product-card-3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.intern-container-product-card-3 p {
    margin: 0;
}

.container-progress-bar {
    width: 100px;
    height: 11px;
    border-radius: 17px;
    background-color: #D9D9D9;
    display: flex;
    align-items: flex-start;
}

.progress-bar {
    height: 100%;
    width: 80%;
    background-color: #7CB4A8;
    border-radius: 17px;
}