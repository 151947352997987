.container-challenge-name{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subtitle-challenges{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 24px;
    margin: 0;
    margin-bottom: 15px;
}

.text-name-challenge{
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
}

.container-bloc-challenge-trimestre{
    display: flex;
    align-items: center;
    height: 100px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
}

.container-challenge-value{
    display: flex;
    flex-direction: column;
    padding-right: 8%;
}

.container-challenge-value:last-child{
    margin-left: auto;
    padding-right: 4%;
}

.first{
    padding-left: 4%;
}

.text-value-challenge{
    font-family: 'Montserrat', sans-serif;
    margin: 0;
}

.container-button-moreinfo-sell{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 10px;
}

