@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-faq{
    display: flex;
}

.container-all-data-faq{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: 'Montserrat', sans-serif;
    margin-left: 320px;
}

.title-faq{
    margin: 0;
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 50px;
}

.container-content-help{
    display: flex;
    max-height: 80vh;
    margin-top: 30px
}

.container-one-label-faq{
    display: flex;
    justify-content: space-between;
    background: #FDFAFA;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 250px;
    height: 60px;
    align-items: center;   
    margin-bottom: 5px;
    cursor: pointer;
}

.container-menu-help{
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-right: 90px;
}

.text-menu-faq{
    margin: 0;
   
}

.container-text-call-faq{
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.text-faq{
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
}

.icon-faq {
   
    transition: transform 0.3s ease;
}

.icon-faq.open {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}

.text-reponse-faq {
    display: flex;
    user-select: none;
    flex-direction: column;
}

.item-question-faq{
    border-bottom: 1px solid;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    user-select: none;
    font-weight: bold;
    cursor: pointer;
}

.container-text-call-faq {
    width: 100%;
    height: 100%;

}

.label-menu-faq{
    margin-left: 15px;
}

.container-all-question-faq{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10%;
    
}

.container-question-faq{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80vh;
    margin-right: 50px;
}

.green{
    color: #7CB4A8;
}

img.challengefaq-img {
    width: 50%;
    min-width: 380px;
    align-self: center;
}
.bold-text {
    font-weight: 700;
    }

/* .container-all-question {
    overflow: auto;
} */
