
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-all-addSell{
    display: flex;
}

.container-moreInfo-sell{
    display: flex;
    align-items: center;
    cursor: pointer;
}

.icon-moreInfo-sell{
    height: 24px;
}

.text-moreInfo-sell{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    margin: 0;
    margin-left: 5px
}

.button-addSell{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 40px;
    background-color: #1B1C3C;
    border-radius: 10px;
    margin-left: 15px;
    cursor: pointer;
}

.icon-addSell{
    height: 24px;
    margin-right: 10px;
}

.text-addSell{
    color: white;
    font-family: 'Montserrat', sans-serif;
    cursor: pointer;
}