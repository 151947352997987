@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-requiredElement{
    display: flex;
    align-items: center;
    min-width: 160px;
}

.icon-requiredElement{
    height: 24px;
    margin-right: 5px;
}

.text-requiredElement{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}