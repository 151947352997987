@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.big-container-details-challenge-exceptionnel {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    flex-wrap: wrap;
}

.container-details-challenge-exceptionnel-part-one {
    width: 50%;
}

.container-details-challenge-exceptionnel-part-two {
    width: 50%;
    height: 100%;
    text-align: center;
}
.img-challenge-exceptionnel {
    width: 506px;
}



.details-challenge-exceptionnel-container-objectif {
    width: 90%;
    min-width: 475px;
    height: 160px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 40px;
}

@media screen and (max-width: 1440px) {
    .timer-finished-details-challenge-trimestriel-text-1{
        font-size: 18px;
        width: 220px;
    }
    .container-details-challenge-exceptionnel-part-two {
        margin-top: 10%;
        height: 66%;
    }
    .img-challenge-exceptionnel {
        width: 90%;
    }
    .details-challenge-exceptionnel-container-objectif{
        flex-direction: column;
        min-width: 275px;
        height: 235px;
    }
    .container-timer-details-challenge-trimestriel-1{
        height: 100px;
    }
    .container-img-moreInfo-objectif{
        width: 200px;
        justify-content: center !important;
    }
    .details-challenge-exceptionnel-sub-container-objectif-one{
        width: 300px !important;
    }
    .container-progression{
        flex-direction: column !important;
    }
    .details-challenge-exceptionnel-mini-container{
        align-items: center !important;
    }
    #title-objectif-container {
        font-size: 22px !important;
    }
    .timer-details-challenge-trimestriel-value-1{
        font-size: 2vw;
    }
    .timer-details-challenge-trimestriel-text-1{
        font-size: 16px;
    }
    .big-container-details-challenge-exceptionnel{
        height: 800px;
    }
}


.details-challenge-exceptionnel-sub-container-objectif-one {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sub-container-objectif-one-mini-container {
    align-self: start;
}

.details-challenge-exceptionnel-sub-container-objectif-one p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    margin: 0;
}

#title-objectif-container {
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
    font-size: 1.2vw;
}

.details-challenge-exceptionnel-mini-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
}

.details-challenge-exceptionnel-sub-container-objectif-two {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-img-moreInfo-objectif {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-left: -13px;
    cursor: pointer;
}

.img-moreInfo-objectif {
    scale: 0.5;
    margin: 0;
}

.container-progression {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
}

.text-progression {
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
}

.container-header-list-product {
    margin-top: 25px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container-list-product {
    width: 90%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-ventes-add-exceptionnel {
    background-color: #1B1C3C;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    border-radius: 15px;
    padding: 2px 25px;
    color: #fff;
    max-height: 63px;
}