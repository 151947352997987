@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

/* TABLEAU VENTES */

.container-subtitle-ventes{
    width: 70%;
    min-width: 610px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.subtitle-ventes{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 24px;
    margin: 0;
    margin-bottom: 2vh;
}

.add-product-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-family: 'Montserrat', sans-serif;
}

.big-container-ventes {
    width: 100%;
    min-height: 310px;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;
}

/* PREUVES VENTES */

.container-depot-file{
    width: 28%;
    max-width: 310px;
    height: 310px;
    min-width: 253px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

.depot-file-title {
    font-family: 'Montserrat', sans-serif;
    color: #1B1C3C;
    font-size: 20px;
}

#form-file-upload {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  #input-file-upload {
    display: none;
  }
  
  #label-file-upload {
    width: 90%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
    cursor: pointer;
  }
  
  #label-file-upload.drag-active {
    background-color: #ffffff;
  }
  
  .upload-button:hover {
    text-decoration-line: underline;
  }
  
  #drag-file-element {
    position: absolute;
    width: 80%;
    height: fit-content;
    border-radius: 1rem;
  }

  .container-info-depot {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    color: #7CB4A8;
    border-width: 1px;
    border-style: dashed;
    border-color: #7CB4A8;
    cursor: default;
    padding-right: 5px;
    cursor: pointer;
  }

  .depot-file-subtitle {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #1B1C3C;
    font-size: 12px;
    cursor: pointer;
  }

  .file-info {
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .file-info img {
    cursor: pointer;
  }

  .container-button-save-data-ventes {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .button-save-data-ventes {
    background-color: #1B1C3C;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    border-radius: 5px;
    padding: 10px;
    outline: none;
    cursor: pointer;
  }

  .depot-file-warning{
    display: flex;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 900;
    color: #1B1C3C;
    font-size: 15px;
    text-align: center;
    font-weight: 700;
    padding-left: 10%;
    padding-right: 10%;
    text-align: justify;
    align-self: center;
    gap: 20px;
    text-align: center;
  }

  .checkbox-label{
    color: #1B1C3C;
    font-family: 'Montserrat', sans-serif;
    margin-left: 10px;
  }