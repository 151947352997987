.container-tab-ventes {
    width: 70%;
    min-width: 710px;
}

.table-header {
    overflow: hidden; 
    width: 100%;
    min-width: 710px;
}

.table-header table {
    width: 100%;
}

.table-body table {
    width: 100%;
}

.table-body {
    max-height: 310px;
    min-width: 710px;
    overflow-y: auto; 
}


.title-listingElement {
    min-width: 130px;
    height: 40px;
    background-color: #1B1C3C;
    color: white;
    font-family: 'Montserrat', sans-serif;
}

.item-listingElement {
    min-width: 130px;
    max-width: 290px;
    height: 40px;
    background-color: #E8E6E5;
    color: #8a8a8a;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

.pointer {
    cursor: pointer;
}

.custom-input {
    border: none;
    background-color: transparent;
    padding: 5px;
    color: #1B1C3C;
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    outline: none;
    text-align: center;
}

.totals {
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.title-totaux p span {
    color: #7CB4A8;
    font-weight: 900;
}

.title-totaux {
    min-width: 130px;
    height: 40px;
    font-family: 'Montserrat', sans-serif;
}