@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.input-find-historique {
    padding-left: 30px;
    border-radius: 10px;
    height: 40px;
    width: 290px;
    border: none;
    background-color: #E8E6E5;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    padding-left: 40px;
}
.find-historique-searchBar{
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 40px;
}

.input-find-historique::placeholder{
    font-family: 'Montserrat', sans-serif;
}

.input-find-historique:focus{
    background-color: #fff;
    border-color: #7CB4A8;
}

.table-historique {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 0 10px;  
}
.table-historique-vente {
    width: 80%;
    border-collapse: separate; 
    border-spacing: 0 10px;  
}
.container-historique-vente-doc {
    align-items: center;
    gap: 2%;
    display: flex;
}

.th-historique,.td-historique{
    font-family: 'Montserrat', sans-serif;
}

.tr-clients-historique {
    border-radius: 10px; 
    overflow: hidden; 
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
}

.td-historique{
    padding: 30px;
    text-align: center;
}

.container-line-moreinfo {
    display: flex;
    justify-content: end;
    padding-right: 15px;
}

.container-line-moreinfo img {
    scale: 0.5;
}

.pagination-historique{
    width: 100%;
    display: flex;
    justify-content: center;
}

.button-pagination-historique{
    font-family: 'Montserrat', sans-serif;
    background-color: #1B1C3C;
    font-weight: bold;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.button-pagination-historique:hover{
    background-color: #E8E6E5;
    color: #1B1C3C;
    border-color: #1B1C3C;
}

.div-bottomTabButtons-historique{
    display: flex;
}

.td-historique-ventes-file-col {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    height: 109px;
    gap: 5px;
}

.historique-ventes-container-col-file{
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 71px;
}

.container-col-modif-ventes{
    display: flex;
    align-items: center;
    justify-content: end;
    padding-right: 5px;
    gap: 5px;
}

.container-header-graph {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px;
}

.container-footer-graph {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.text-footer-graph {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
}

.pointer {
    cursor: pointer;
}

/* Dans historiqueList.css ou un autre fichier CSS pertinent */

@media screen and (min-width: 1020px) and (max-width: 1280px) {
    .container-historique-vente-doc {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .container-depot-doc {
      order: -1; /* Positionner le FileHandler au-dessus de la table */
      margin-bottom: 20px; 
    }
  
    .table-historique-vente {
      width: 100%; 
    }
  }
  