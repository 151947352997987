.add-document, .view-document{
    display: flex;
    align-items: center;
    flex-direction: column;
    /* justify-content: center; */
    /* gap: 8px;
    height: 57px; */
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    border-radius: 15px;
    padding: 2px 25px;
    color: #000;
    border: 1px solid #ccc;
    height: 202px;
}

.pointer {
    cursor: pointer;
}


.add-document-doc{
    display: flex;
    align-items: center;
}

.container-view-doc {
    display: flex;
    align-items: center;
    color: #7CB4A8;
    border-width: 1px;
    border-style: dashed;
    border-color: #7CB4A8;
    cursor: pointer;
    margin-top: 13%;
    width: 100%;
    padding: 3%;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    color: #000; 
  }
  
  .container-depot-doc {
    position: relative;
  }