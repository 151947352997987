@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

form{
    width: 20vw;
    max-width: 300px;
}

.container-label-text-field{
    min-width: 300px;
}

.container-connexion-field{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.container-label-input{
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.container-label-input-first{
    display: flex;
    flex-direction: column;
    min-width: 300px;
}

.label-input{
    font-size: 18px;
    padding-bottom: 2px;
    font-family: 'Montserrat', sans-serif;
}

.input-first{
    margin-bottom: 20px;
    background-color: #E8E6E5;
    border: none;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    padding: 10px;
}

.input{
    background-color: #E8E6E5;
    border: none;
    padding: 10px;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
}

.submit-btn-inscription{
    height: 50px;
    width: 190px;
    background-color: #1B1C3C;
    color: white;
    font-family: 'Montserrat', sans-serif;
    border-radius: 10px;
    font-size: 18px;
    margin-top: 35px;
}
/* .loaderInput{
    position :absolute;
    left:53% ;
    top: 39%;
    width: 30px;
} */


.container-btn{
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    min-width: 300px;
}

.text-inscription{
    font-size: 16px;
    text-decoration: none;
    color: #1B1C3C;
    font-weight: 700;
    padding-top: 10px;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
}

.icon-information{
    width: 17px;
    text-align: center;
    margin-left: 5px;
    cursor: pointer;
}
.input-pharmaName {
    display: flex;
    justify-content: space-between;
    background-color: #E8E6E5;
    border-radius: 5px;
    border: none;
    align-items: center;
}
.input-pharmaName input{
    width: 90%;
}
.loaderInput{
    height: 18px;
    text-align: center;
    margin-left: 5px;
    width: 20px;
    height: 20px;
    padding-right: 4%;
}
.container-inputPass-icon{
    display: flex;
    align-items: center;
}

.input-pswd {
    display: flex;
    justify-content: space-between;
    background-color: #E8E6E5;
    border-radius: 5px;
    border: none;
    padding: 5px;
}

.input-pswd input {
    background-color: transparent;
    border: none;
    outline: none;
    width: 90%;
    font-family: 'Montserrat', sans-serif;
}

.error-message-connexion {
    min-width: 300px;
}

.eye-icn {
    cursor: pointer;
}