@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-profilPassword{
    display: flex;
}

.container-all-data-profilPassword{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-left: 320px;
}

.container-form-profilPassword{
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}

.error-message-updatePwd {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    color: red;
}