.container-listing-products {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 20px; 
    justify-items: center; 
}

@media screen and (max-width: 1400px) {
    .container-listing-products {
        grid-template-columns: repeat(3, 1fr);
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-page-products {
    font-family: 'Montserrat', sans-serif;
    background-color: #1B1C3C;
    font-weight: bold;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
    transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.btn-page-products:hover{
    background-color: #E8E6E5;
    color: #1B1C3C;
    border-color: #1B1C3C;
}

.listing-products {
    margin-top: 220px;
}
.text-pagination {
    font-family: 'Montserrat', sans-serif;
}