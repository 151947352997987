@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

.container-profil{
    display: flex;
}

.container-all-data-profil{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    margin-left: 320px
}

.container-form-profil{
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}

.img-modif-profil{
    position: absolute;
    right: 0;
    margin-right: 10px;
}

.container-input-img{
    position: relative;
    display: flex;
    align-items: center;
}

.pointer{
    cursor: pointer;
}

.form-profil {
    min-width: 300px;
}